const actions = {
  SET_STATE: 'users/SET_STATE',
  CREATE: 'users/CREATE',
  UPDATE: 'users/UPDATE',
  LOAD_USERS:'users/LOAD_USERS',
  SEARCH_USERS:'users/SEARCH_USERS',
  DELETE_USERS:'users/DELETE_USERS',
}

export default actions
