import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
/* eslint-disable */
const API = 'https://ghost.cudoapp.com/api/admin/'
const firebaseConfig = {
  apiKey: 'AIzaSyAE5G0RI2LwzwTBizhJbnRKIKbiXQIA1dY',
  authDomain: 'cleanui-72a42.firebaseapp.com',
  databaseURL: 'https://cleanui-72a42.firebaseio.com',
  projectId: 'cleanui-72a42',
  storageBucket: 'cleanui-72a42.appspot.com',
  messagingSenderId: '583382839121',
}
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}
function save(token) {
  localStorage.setItem('user_token', token)
}
function parseJSON(response) {
  return JSON.parse(response.response)
}
const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth
export default firebaseApp

function loadOnce() {
  if (!localStorage.justOnce) {
    localStorage.setItem('justOnce', 'true')
    window.location.reload()
  }
}
export async function login(email, password) {
  localStorage.removeItem('justOnce')
  const params = {
    email,
    password,
  }
  return makeRequest(`${API}login`, params, 'POST')
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      if (data.token) {
        save(data.token)
        return true
      }
      notification.warning({
        message: 'Login Error',
        description: data.message,
      })
      return false
    })
    .catch(function(error) {
      notification.warning({
        message: 'Server error',
        description: error.error,
      })
    })
  /**  return fetch(`${API}login`, {
    method: 'POST',
    credentials: "omit",
    body: JSON.stringify({params}),
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      console.log(data)
      if (data.user_token) {
        save(data.user_token)
        return true
      }
      notification.warning({
        message: 'Login Error',
        description: data.error,
      })
      return false
    })

    .catch(function e (error) {
      notification.warning({
        message: 'Server error',
        description: error.error,
      })
    })**/
}

var makeRequest = function(url, body, method, user_token) {
  const request = new XMLHttpRequest()
  if (body) {
    var data = new FormData()
    const keys = Object.keys(body)
    keys.forEach(e => {
      data.append(e, body[e])
    })
  }

  return new Promise(function(resolve, reject) {
    request.onreadystatechange = function() {
      // Only run if the request is complete
      if (request.readyState !== 4) return

      // Process the response
      if (request.status >= 200 && request.status < 300) {
        // If successful
        resolve(request)
      } else {
        // If failed
        reject({
          status: request.status,
          statusText: request.statusText,
        })
      }
    }

    // Setup our HTTP request
    request.open(method || 'GET', url, true)
    if (user_token) {
      request.setRequestHeader('authorization', 'Bearer ' + user_token)
    }
    // Send the request
    request.send(data)
  })
}

export async function currentAccount() {
  const userToken = await localStorage.getItem('user_token')

  return makeRequest(`${API}`, {}, 'GET', userToken)
    .then(parseJSON)
    .then(responseJson => {
      loadOnce()
      if (responseJson.admin.sub === 1) {
        localStorage.setItem('sub', true)
      }
      if (responseJson.admin.sub === 0) {
        localStorage.setItem('sub', false)
      }
      if (!responseJson.status) {
        return responseJson.message
      }
      if (responseJson.admin) {
        return responseJson.admin
      }
      window.location.reload()
    })
    .catch(error => {})
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true)
}
