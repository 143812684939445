/* eslint-disable */
import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import classNames from 'classnames'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import Settings from 'components/LayoutComponents/Settings'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
})

@withRouter
@connect(mapStateToProps)
class MainLayout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
  }

  // _onAction = (e) => {
  //   console.log('user did something', e)
  // }

  // _onActive = (e) => {
  //   console.log('user is active', e)
  //   console.log('time remaining', this.idleTimer.getRemainingTime())
  // }

  _onIdle = e => {
    localStorage.clear()
    this.props.history.push('/user/login')
    window.location.reload()
  }

  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
    } = this.props
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 10}
        />

        <Layout
          className={classNames({
            settings__borderLess: isBorderless,
            settings__squaredBorders: isSquaredBorders,
            settings__fixedWidth: isFixedWidth,
            settings__menuShadow: isMenuShadow,
            settings__menuTop: isMenuTop,
          })}
        >
          <BackTop />
          <Menu />
          <Settings />
          <Layout>
            <Layout.Header>
              <TopBar />
            </Layout.Header>
            <Layout.Content style={{ height: '100%', position: 'relative' }}>
              <div className="utils__content">{children}</div>
            </Layout.Content>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        </Layout>
      </>
    )
  }
}

export default MainLayout
