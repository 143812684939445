import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import category from './category/reducers'
import users from './users/reducers'
import partner from './partner/reducers'
import staging from './staging/reducers'
import coupon from './coupon/reducers'
import blog from './blog/reducers'
import admin from './admin/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    category, 
    coupon, 
    users,
    partner,
    staging,
    blog, 
    admin
  })
