import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { createUser, update, loaduser, deleteuser, searchuser } from 'services/users'
import { history } from 'index'
import actions from './actions'

export function* CREATE({ payload }) {
  const { name, email, phone, password, bio, dob, gender } = payload
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (!(name && email && phone && password && bio && dob && gender)) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(createUser, name, email, password, bio, phone, dob, gender)
  if (success) {
    notification.success({
      message: 'User has been created successfully',
      description: 'You have successfully added a new user!',
    })
    history.push('/users')
    yield put({
      type: 'users/LOAD_USERS',
      payload: { page: 1 },
    })

    yield put({
      type: 'users/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { name, description, Files, id } = payload
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (!id) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }

  const success = yield call(update, id, name, description, Files || {})
  if (success) {
    notification.success({
      message: 'Users Updated Successfully',
      description: 'You have successfully updated your new users!',
    })
    history.push('/users')
    yield put({
      type: 'users/LOAD_USERS',
      payload: { limit: 10, skip: 0 },
    })

    yield put({
      type: 'users/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_USERS(p) {
  const payload = p.payload || { page: 1 }
  const { page } = payload

  const response = yield call(loaduser, page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'users/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
        mode: 'user',
        query: '',
      },
    })
  }
}
export function* SEARCH_USERS({ payload }) {
  console.log(payload)
  const { query, page } = payload

  const response = yield call(searchuser, query, page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'users/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
        mode: 'search',
        query,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  if (!id) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(deleteuser, id)
  if (success) {
    notification.success({
      message: 'Users Deleted Successfully',
      description: 'You have successfully deleted a users!',
    })

    yield put({
      type: 'users/LOAD_USERS',
      payload: { limit: 10, skip: 0 },
    })

    history.push('/users')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_USERS, LOAD_USERS),
    takeEvery(actions.SEARCH_USERS, SEARCH_USERS),
    takeEvery(actions.DELETE_USERS, DELETE),
    LOAD_USERS({ limit: 10, skip: 0 }), // run once on app load to check users auth
  ])
}
