import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { createCoupon, update, loadcoupon, deletecoupon } from 'services/coupon'
import { history } from 'index'
import actions from './actions'

export function* CREATE({ payload }) {
  const { code, maximumUsage, expires, type, percentage, amount, user } = payload
  yield put({
    type: 'coupon/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (type === 'free') {
    console.log('heree 1')
    if (!(code && maximumUsage && expires && type && user)) {
      notification.error({
        message: 'Error',
        description: 'Please fill the form properly!',
      })
      return
    }
  }
  if (type !== 'free') {
    console.log('heree 2')
    if (!(code && maximumUsage && expires && type && (percentage || amount) && user)) {
      notification.error({
        message: 'Error',
        description: 'Please fill the form properly!',
      })
      return
    }
  }
  const success = yield call(
    createCoupon,
    code,
    maximumUsage,
    expires,
    type,
    percentage,
    amount,
    user,
  )
  if (success) {
    notification.success({
      message: 'Coupon Created && Assigned Successfully',
      description: `You have successfully created and assigned a new coupon to ${user}!`,
    })
    history.push('/coupons')
    yield put({
      type: 'coupon/LOAD_COUPON',
      payload: { page: 1 },
    })

    yield put({
      type: 'coupon/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, code, maximumUsage, expires, type, percentage, amount, user } = payload
  yield put({
    type: 'coupon/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (type === 'free') {
    console.log('heree 1')
    if (!(id && code && maximumUsage && expires && type && user)) {
      notification.error({
        message: 'Error',
        description: 'Please fill the form properly!',
      })
      return
    }
  }
  if (type !== 'free') {
    console.log('heree 2')
    if (!(id && code && maximumUsage && expires && type && (percentage || amount) && user)) {
      notification.error({
        message: 'Error',
        description: 'Please fill the form properly!',
      })
      return
    }
  }

  const success = yield call(
    update,
    id,
    code,
    maximumUsage,
    expires,
    type,
    percentage,
    amount,
    user,
  )
  if (success) {
    notification.success({
      message: 'Coupon Updated Successfully',
      description: `You have successfully created and assigned a new coupon to ${user}!`,
    })
    history.push('/coupons')
    yield put({
      type: 'coupon/LOAD_COUPON',
      payload: { limit: 10, skip: 0 },
    })

    yield put({
      type: 'coupon/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_COUPON(p) {
  const payload = p.payload || { page: 1 }
  const { page } = payload

  const response = yield call(loadcoupon, page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'coupon/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
      },
    })
  }
}

export function* DELETE(payload) {
  const { id } = payload
  if (!id) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(deletecoupon, id)
  console.log(success)
  if (success) {
    notification.success({
      message: 'Coupon Deleted Successfully',
      description: 'You have successfully deleted a coupon!',
    })

    yield put({
      type: 'coupon/LOAD_COUPON',
      payload: { limit: 10, skip: 0 },
    })

    history.push('/coupons')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_COUPON, LOAD_COUPON),
    takeEvery(actions.DELETE_COUPON, DELETE),
    LOAD_COUPON({ limit: 10, skip: 0 }), // run once on app load to check coupon auth
  ])
}
