const actions = {
  SET_STATE: 'partner/SET_STATE',
  CREATE: 'partner/CREATE',
  UPGRADE: 'partner/UPGRADE',
  UPDATE: 'partner/UPDATE',
  LOAD_PARTNER: 'partner/LOAD_PARTNER',
  SEARCH_PARTNER: 'partner/SEARCH_PARTNER',
  DELETE_PARTNER: 'partner/DELETE_PARTNER',
}

export default actions
