/* eslint-disable */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { createPartner, update, loadpartner, deletepartner, searchpartner } from 'services/partner'
import { history } from 'index'
import actions from './actions'

export function* CREATE({ payload }) {
  const {
    name,
    email,
    phone,
    lga,
    post_code,
    street,
    town,
    state,
    country,
    gender,
    dob,
    password,
    category,
    Files,
  } = payload
  yield put({
    type: 'partner/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (
    !(
      name &&
      email &&
      phone &&
      lga &&
      post_code &&
      street &&
      town &&
      state &&
      country &&
      gender &&
      dob &&
      password &&
      Files
    )
  ) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(
    createPartner,
    name,
    email,
    phone,
    lga,
    post_code,
    street,
    town,
    state,
    country,
    gender,
    dob,
    password,
    category,
    Files,
  )
  if (success) {
    notification.success({
      message: 'Partner Created Successfully',
      description: 'You have successfully added a new partner!',
    })
    history.push('/partners/list')
    yield put({
      type: 'partner/LOAD_PARTNER',
      payload: { page: 1 },
    })

    yield put({
      type: 'partner/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { name, description, Files, id } = payload
  yield put({
    type: 'partner/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (!id) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }

  const success = yield call(update, id, name, description, Files || {})
  if (success) {
    notification.success({
      message: 'Partner Updated Successfully',
      description: 'You have successfully updated your new partner!',
    })
    history.push('/partner')
    yield put({
      type: 'partner/LOAD_PARTNER',
      payload: { limit: 10, skip: 0 },
    })

    yield put({
      type: 'partner/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_PARTNER(p) {
  const payload = p.payload || { page: 1 }
  const { page } = payload

  const response = yield call(loadpartner, page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'partner/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
        mode: 'partner',
        query: '',
      },
    })
  }
}
export function* SEARCH_PARTNER({ payload }) {
  console.log(payload)
  const { query, page } = payload

  const response = yield call(searchpartner, query, page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'partner/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
        mode: 'search',
        query,
      },
    })
  }
}

export function* DELETE(payload) {
  const { id } = payload
  if (!id) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(deletepartner, id)
  if (success) {
    notification.success({
      message: 'Partner Deleted Successfully',
      description: 'You have successfully deleted a partner!',
    })

    yield put({
      type: 'partner/LOAD_PARTNER',
      payload: { limit: 10, skip: 0 },
    })

    history.push('/partner')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_PARTNER, LOAD_PARTNER),
    takeEvery(actions.SEARCH_PARTNER, SEARCH_PARTNER),
    takeEvery(actions.DELETE_PARTNER, DELETE),
    LOAD_PARTNER({ limit: 10, skip: 0 }), // run once on app load to check partner auth
  ])
}
