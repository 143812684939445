import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { create, update, loadcategory, deletecategory } from 'services/category'
import { history } from 'index'
import actions from './actions'

export function* CREATE({ payload }) {
  const { name, description, Files } = payload
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (!(name && description && Files)) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(create, name, description, Files)
  if (success) {
    notification.success({
      message: 'Category Created Successfully',
      description: 'You have successfully added a new category!',
    })
    history.push('/category')
    yield put({
      type: 'category/LOAD_CATEGORY',
      payload: { page: 1 },
    })

    yield put({
      type: 'category/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { name, description, Files, id } = payload
  yield put({
    type: 'category/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (!id) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }

  const success = yield call(update, id, name, description, Files || {})
  if (success) {
    notification.success({
      message: 'Category Updated Successfully',
      description: 'You have successfully updated your new category!',
    })
    history.push('/category')
    yield put({
      type: 'category/LOAD_CATEGORY',
      payload: { limit: 10, skip: 0 },
    })

    yield put({
      type: 'category/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CATEGORY(p) {
  const payload = p.payload || { page: 1 }
  const { page } = payload

  const response = yield call(loadcategory, page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'category/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
      },
    })
  }
}

export function* DELETE(payload) {
  const { id } = payload
  if (!id) {
    notification.error({
      message: 'Error',
      description: 'All the fields are compulsory!',
    })
    return
  }
  const success = yield call(deletecategory, id)
  console.log(success)
  if (success) {
    notification.success({
      message: 'Category Deleted Successfully',
      description: 'You have successfully deleted a category!',
    })

    yield put({
      type: 'category/LOAD_CATEGORY',
      payload: { limit: 10, skip: 0 },
    })

    history.push('/category')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_CATEGORY, LOAD_CATEGORY),
    takeEvery(actions.DELETE_CATEGORY, DELETE),
    LOAD_CATEGORY({ limit: 10, skip: 0 }), // run once on app load to check category auth
  ])
}
