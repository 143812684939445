const actions = {
  SET_STATE: 'staging/SET_STATE',
  CREATE: 'staging/CREATE',
  UPDATE: 'staging/UPDATE',
  LOAD_STAGING:'staging/LOAD_STAGING',
  SEARCH_STAGING:'staging/SEARCH_STAGING',
  DELETE_STAGING:'staging/DELETE_STAGING',
}

export default actions
