import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })
let routes
const subAdmin = localStorage.getItem('sub')
if (subAdmin === 'true') {
  routes = [
    // System Pages
    {
      path: '/user/login',
      component: loadable(() => import('pages/user/login')),
      exact: true,
    },
    {
      path: '/user/forgot',
      component: loadable(() => import('pages/user/forgot')),
      exact: true,
    },

    // Dashboards

    {
      path: '/newpartner',
      component: loadable(() => import('pages/newpartner')),
      exact: true,
    },
    {
      path: '/upgradepartner',
      component: loadable(() => import('pages/upgradepartner')),
      exact: true,
    },
    {
      path: '/partner/activate',
      component: loadable(() => import('pages/partnereachstaging')),
      exact: true,
    },

    {
      path: '/levels',
      component: loadable(() => import('pages/levels')),
      exact: true,
    },
    {
      path: '/newlevel',
      component: loadable(() => import('pages/newlevel')),
      exact: true,
    },
    {
      path: '/partners/list',
      component: loadable(() => import('pages/partners/list')),
      exact: true,
    },
    {
      path: '/partners/activate',
      component: loadable(() => import('pages/partners/activate')),
      exact: true,
    },
    {
      path: '/partnereach',
      component: loadable(() => import('pages/partnereach')),
      exact: true,
    },
    {
      path: '/blogs',
      component: loadable(() => import('pages/blogs')),
      exact: true,
    },
    {
      path: '/blogeach',
      component: loadable(() => import('pages/blogeach')),
      exact: true,
    },
    {
      path: '/newblog',
      component: loadable(() => import('pages/newblog')),
      exact: true,
    },

    {
      path: '/dashboard/docs',
      component: loadable(() => import('pages/dashboard/docs')),
      exact: true,
    },
    {
      path: '/apps/profile',
      component: loadable(() => import('pages/apps/profile')),
      exact: true,
    },
  ]
} else {
  routes = [
    // System Pages
    {
      path: '/user/login',
      component: loadable(() => import('pages/user/login')),
      exact: true,
    },
    {
      path: '/user/forgot',
      component: loadable(() => import('pages/user/forgot')),
      exact: true,
    },

    // Dashboards
    {
      path: '/dashboard/alpha',
      component: loadable(() => import('pages/dashboard/alpha')),
    },

    {
      path: '/category',
      component: loadable(() => import('pages/category')),
      exact: true,
    },
    {
      path: '/categoryeach',
      component: loadable(() => import('pages/categoryeach')),
      exact: true,
    },
    {
      path: '/newcategory',
      component: loadable(() => import('pages/newcategory')),
      exact: true,
    },
    {
      path: '/newpartner',
      component: loadable(() => import('pages/newpartner')),
      exact: true,
    },
    {
      path: '/upgradepartner',
      component: loadable(() => import('pages/upgradepartner')),
      exact: true,
    },
    {
      path: '/partner/activate',
      component: loadable(() => import('pages/partnereachstaging')),
      exact: true,
    },
    {
      path: '/partners/insights',
      component: loadable(() => import('pages/insights')),
      exact: true,
    },
    {
      path: '/admininsights',
      component: loadable(() => import('pages/admininsights')),
      exact: true,
    },
    {
      path: '/assigncoupon',
      component: loadable(() => import('pages/assigncoupon')),
      exact: true,
    },
    {
      path: '/users',
      component: loadable(() => import('pages/users')),
      exact: true,
    },
    {
      path: '/levels',
      component: loadable(() => import('pages/levels')),
      exact: true,
    },
    {
      path: '/newlevel',
      component: loadable(() => import('pages/newlevel')),
      exact: true,
    },
    {
      path: '/newuser',
      component: loadable(() => import('pages/newuser')),
      exact: true,
    },
    {
      path: '/userseach',
      component: loadable(() => import('pages/usereach')),
      exact: true,
    },
    {
      path: '/partners/list',
      component: loadable(() => import('pages/partners/list')),
      exact: true,
    },
    {
      path: '/partners/activate',
      component: loadable(() => import('pages/partners/activate')),
      exact: true,
    },
    {
      path: '/partnereach',
      component: loadable(() => import('pages/partnereach')),
      exact: true,
    },
    {
      path: '/blogs',
      component: loadable(() => import('pages/blogs')),
      exact: true,
    },
    {
      path: '/blogeach',
      component: loadable(() => import('pages/blogeach')),
      exact: true,
    },
    {
      path: '/newblog',
      component: loadable(() => import('pages/newblog')),
      exact: true,
    },
    {
      path: '/admins',
      component: loadable(() => import('pages/admins')),
      exact: true,
    },
    {
      path: '/admineach',
      component: loadable(() => import('pages/admineach')),
      exact: true,
    },
    {
      path: '/newadmin',
      component: loadable(() => import('pages/newadmin')),
      exact: true,
    },
    {
      path: '/coupons',
      component: loadable(() => import('pages/coupon')),
      exact: true,
    },
    {
      path: '/couponeach',
      component: loadable(() => import('pages/couponeach')),
      exact: true,
    },
    {
      path: '/newcoupon',
      component: loadable(() => import('pages/newcoupon')),
      exact: true,
    },
    {
      path: '/dashboard/docs',
      component: loadable(() => import('pages/dashboard/docs')),
      exact: true,
    },
    {
      path: '/apps/profile',
      component: loadable(() => import('pages/apps/profile')),
      exact: true,
    },
  ]
}

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
