import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { create, update, loadadmin,deleteadmin } from 'services/admin'
import { history } from 'index'
import actions from './actions'

export function* CREATE  ({ payload }) {
  const { name, email , password,  } = payload
 
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (!(name && email && password)) {
    notification.error({
      message: 'Please fill the form properly!',
      url: '',
    })
    return
  }
  const success = yield call(create, name, email, password)
  if (success) {
    notification.success({
      message: 'Admin Created Successfully',
      url: 'You have successfully added a new admin!',
    })
    history.push('/admins');
    yield put({
      type: 'admin/LOAD_ADMIN',
      payload: {  page:1  },
    })
   
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    
  }
}

export function* UPDATE( {payload} ) {
  const { name,  id } = payload
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (!(  id)) {
    notification.error({
      message: 'Error',
      url: 'Please fill the form properly!',
    })
    return
  }

  const success = yield call(update, id, name)
  if (success) {
    notification.success({
      message: 'Admin Updated Successfully',
      url: 'You have successfully updated your new admin!',
    })
    history.push('/admins');
    yield put({
      type: 'admin/LOAD_ADMIN',
      payload: {  limit: 10, skip: 0 },
    
    })
 
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_ADMIN( p ) {
  const payload = p.payload ||{page: 1}
  const {  page } = payload

  const response = yield call(loadadmin,  page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page
      },
    })
    
  }
}


export function* DELETE  ( payload ) {
  const { id} = payload
  if (!(id)) {
    notification.error({
      message: 'Error',
      url: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(deleteadmin, id);
  console.log(success)
  if (success) {
    notification.success({
      message: 'Admin Deleted Successfully',
      url: 'You have successfully deleted a admin!',
    })

    yield put({
      type: 'admin/LOAD_ADMIN',
      payload: {  limit: 10, skip: 0 },
    })
   
   
    history.push('/admin');
    
  }
}






export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_ADMIN, LOAD_ADMIN),
    takeEvery(actions.DELETE_ADMIN, DELETE),
   LOAD_ADMIN( { limit: 10, skip: 0 }), // run once on app load to check admin auth
  ])
}
