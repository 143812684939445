import { notification } from 'antd'
/* eslint-disable */
const API = 'https://ghost.cudoapp.com/api/admin/settings/'

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

function parseJSON(response) {
  return JSON.parse(response.response)
}

function getToken() {
  return localStorage.getItem('user_token')
}

var makeRequest = function(url, body, method, user_token, headers) {
  let data = new FormData()
  const request = new XMLHttpRequest()
  if (body && !body instanceof FormData) {
    const keys = Object.keys(body)
    keys.forEach(e => {
      data.append(e, body[e])
    })
  } else {
    data = body
  }

  return new Promise(function(resolve, reject) {
    request.onreadystatechange = function() {
      // Only run if the request is complete
      if (request.readyState !== 4) return

      // Process the response
      if (request.status >= 200 && request.status < 300) {
        // If successful
        resolve(request)
      } else {
        // If failed
        console.log(request)
        reject({
          status: request.status,
          statusText: request.statusText,
        })
      }
    }
    // Setup our HTTP request
    request.open(method || 'GET', url, true)
    request.setRequestHeader('Accept', 'application/json')
    if (user_token) {
      request.setRequestHeader('authorization', 'Bearer ' + user_token)
    }
    if (headers) {
      // request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    }
    // Send the request
    request.send(data)
  })
}

export async function getadmin(id, callback) {
  callback = typeof callback === 'function' ? callback : function c() {}
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}admin/${id}`, {}, 'GET', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      console.log(responseJson)
      if (!responseJson.status) {
        callback(responseJson.message)
      }
      if (responseJson.status) {
        callback(false, responseJson.admin)
      }
    })
    .catch(error => {
      console.error(error)
    })
}

export async function deleteadmin(id, callback) {
  callback = typeof callback === 'function' ? callback : function c() {}
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}admin/${id}`, {}, 'DELETE', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      if (!responseJson.status) {
        callback(responseJson.message)
      }
      if (responseJson.status) {
        callback(false, responseJson.message)
      }
    })
    .catch(error => {
      console.error(error)
    })
}

export async function create(name, email, password) {
  console.log(name, email, password)
  const formData = new FormData()
  formData.append('name', name)

  formData.append('email', email)
  formData.append('password', password)
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}admin`, formData, 'POST', userToken, false)
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      console.log(data)
      if (data.status) {
        return true
      }
      notification.warning({
        message: 'admin Creation Error',
        url: data.error,
      })
      return false
    })
    .catch(function e(error) {
      console.log(error)
      notification.warning({
        message: 'Server error',
        url: error.error,
      })
    })
}

export async function update(id, name) {
  const formData = new FormData()
  formData.append('name', name)

  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}admin/${id}`, formData, 'POST', userToken, true)
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      console.log(data)
      if (data.status) {
        return true
      }
      notification.warning({
        message: 'admin Update Error',
        url: data.message,
      })
      return false
    })
    .catch(function e(error) {
      notification.warning({
        message: 'Server error',
        url: error.error,
      })
    })
}

export async function loadadmin(page) {
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}admin?page=${page}`, {}, 'GET', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      console.log(responseJson)
      if (responseJson.status) {
        return responseJson.admins
      }
      if (!responseJson.status) {
        return responseJson.message
      }
      return responseJson
    })
    .catch(error => {
      console.error(error)
    })
}
