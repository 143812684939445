import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { create, update,loadstaging,deletestaging, searchstaging} from 'services/staging'
import { history } from 'index'
import actions from './actions'

export function* CREATE  ({ payload }) {
  const { name, description, Files } = payload
  yield put({
    type: 'staging/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (!(name && description  && Files)) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(create, name, description,  Files)
  if (success) {
    notification.success({
      message: 'Staging Partner Created Successfully',
      description: 'You have successfully added a new staging partner!',
    })
    history.push('/staging');
    yield put({
      type: 'staging/LOAD_STAGING',
      payload: {  page:1  },
    })
   
    yield put({
      type: 'staging/SET_STATE',
      payload: {
        loading: false,
      },
    })
    
  }
}

export function* UPDATE( {payload} ) {
  const { name,  description,Files,  id } = payload
  yield put({
    type: 'staging/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (!( id)) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }

  const success = yield call(update, id, name,  description,Files||{})
  if (success) {
    notification.success({
      message: 'Staging Updated Successfully',
      description: 'You have successfully updated your new staging!',
    })
    history.push('/staging');
    yield put({
      type: 'staging/LOAD_STAGING',
      payload: {  limit: 10, skip: 0 },
    
    })
 
    yield put({
      type: 'staging/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_STAGING( p ) {
  const payload = p.payload ||{page: 1}
  const {  page } = payload

  const response = yield call(loadstaging,  page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'staging/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
        mode:'staging',query:''
      },
    })
    
  }
}
export function* SEARCH_STAGING( {payload} ) {
  console.log(payload)
  const {  query,page } = payload

  const response = yield call(searchstaging,query,  page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'staging/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page,
        mode:'search',
        query
      },
    })
    
  }
}


export function* DELETE  ( payload ) {
  const { id} = payload
  if (!(id)) {
    notification.error({
      message: 'Error',
      description: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(deletestaging, id);
  if (success) {
    notification.success({
      message: 'Staging Deleted Successfully',
      description: 'You have successfully deleted a staging!',
    })

    yield put({
      type: 'staging/LOAD_STAGING',
      payload: {  limit: 10, skip: 0 },
    })
   
   
    history.push('/staging');
    
  }
}






export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_STAGING, LOAD_STAGING),
    takeEvery(actions.SEARCH_STAGING, SEARCH_STAGING),
    takeEvery(actions.DELETE_STAGING, DELETE),
    LOAD_STAGING( { limit: 10, skip: 0 }), // run once on app load to check staging auth
  ])
}
