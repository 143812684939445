import { notification } from 'antd'
/* eslint-disable */
const API = 'https://ghost.cudoapp.com/api/admin/'

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}
function parseJSON(response) {
  return JSON.parse(response.response)
}

function getToken() {
  return localStorage.getItem('user_token')
}
var makeRequest = function(url, body, method, user_token, headers) {
  let data = new FormData()
  const request = new XMLHttpRequest()
  if (body && !body instanceof FormData) {
    const keys = Object.keys(body)
    keys.forEach(e => {
      data.append(e, body[e])
    })
  } else {
    data = body
  }

  return new Promise(function(resolve, reject) {
    request.onreadystatechange = function() {
      // Only run if the request is complete
      if (request.readyState !== 4) return

      // Process the response
      if (request.status >= 200 && request.status < 300) {
        // If successful
        resolve(request)
      } else {
        // If failed
        reject({
          status: request.status,
          statusText: request.statusText,
        })
      }
    }
    // Setup our HTTP request
    request.open(method || 'GET', url, true)
    request.setRequestHeader('Accept', 'application/json')
    if (user_token) {
      request.setRequestHeader('authorization', 'Bearer ' + user_token)
    }
    if (headers) {
      // request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    }
    // Send the request
    request.send(data)
  })
}
export async function getstaging(id, callback) {
  callback = typeof callback === 'function' ? callback : function c() {}
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}staging/${id}`, {}, 'GET', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      console.log(responseJson)
      if (!responseJson.status) {
        callback(responseJson.message)
      }
      if (responseJson.status) {
        callback(false, responseJson.staging)
      }
    })
    .catch(error => {
      console.error(error)
    })
}

export async function deletestaging(id, callback) {
  callback = typeof callback === 'function' ? callback : function c() {}
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}staging/${id}`, {}, 'DELETE', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      if (!responseJson.status) {
        callback(responseJson.message)
      }
      if (responseJson.status) {
        callback(false, responseJson.message)
      }
    })
    .catch(error => {
      console.error(error)
    })
}
export async function create(name, description, Files) {
  const formData = new FormData()
  formData.append('name', name)

  formData.append('description', description)
  formData.append('image', Files.file)
  console.log(name, description, Files.file)

  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}staging`, formData, 'POST', userToken, false)
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      console.log(data)
      if (data.status) {
        return true
      }
      notification.warning({
        message: 'staging Creation Error',
        description: data.error,
      })
      return false
    })
    .catch(function e(error) {
      notification.warning({
        message: 'Server error',
        description: error.error,
      })
    })
}
export async function update(id, name, description, Files) {
  const formData = new FormData()
  formData.append('name', name)

  formData.append('description', description)
  if (Files && Files.file) {
    formData.append('image', Files.file)
  }

  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}staging/${id}`, formData, 'POST', userToken, true)
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      console.log(data)
      if (data.status) {
        return true
      }
      notification.warning({
        message: 'staging Update Error',
        description: data.message,
      })
      return false
    })
    .catch(function e(error) {
      notification.warning({
        message: 'Server error',
        description: error.error,
      })
    })
}
export async function loadstaging(page) {
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}partner/staging?page=${page}`, {}, 'GET', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      console.log(responseJson)
      if (responseJson.status) {
        return responseJson.partners
      }
      if (!responseJson.status) {
        return responseJson.message
      }
      return responseJson
    })
    .catch(error => {
      console.error(error)
    })
}
export async function searchstaging(query, page) {
  const userToken = await localStorage.getItem('user_token')
  return makeRequest(`${API}search?type=staging&query=${query}&page=${page}`, {}, 'GET', userToken)
    .then(checkStatus)
    .then(parseJSON)
    .then(responseJson => {
      console.log(responseJson)
      if (responseJson.status) {
        return responseJson.users
      }
      if (!responseJson.status) {
        return responseJson.message
      }
      return responseJson
    })
    .catch(error => {
      console.error(error)
    })
}
