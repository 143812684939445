const actions = {
  SET_STATE: 'coupon/SET_STATE',
  CREATE: 'coupon/CREATE',
  UPDATE: 'coupon/UPDATE',
  ASSIGNCOUPON: 'coupon/ASSIGNCOUPON',
  LOAD_COUPON: 'coupon/LOAD_COUPON',
  DELETE_COUPON: 'coupon/DELETE_COUPON',
}

export default actions
