import { all } from 'redux-saga/effects'
import user from './user/sagas'
import category from './category/sagas'
import users from './users/sagas'
import partner from './partner/sagas'
import staging from './staging/sagas'
import coupon from './coupon/sagas'
import blog from './blog/sagas'
 import admin from './admin/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), category(), coupon(), blog(), users(), partner(), staging(), 
   admin()
  ])
}
