import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { create, update, loadblog,deleteblog } from 'services/blog'
import { history } from 'index'
import actions from './actions'

export function* CREATE  ({ payload }) {
  const { title, url, type, Files } = payload
  console.log(title, url, type, Files)
  yield put({
    type: 'blog/SET_STATE',
    payload: {
      loading: true,
    },
  })
  if (!(title && url &&type && Files)) {
    notification.error({
      message: 'Please fill the form properly!',
      url: '',
    })
    return
  }
  const success = yield call(create, title, url ,type,  Files)
  if (success) {
    notification.success({
      message: 'Blog Created Successfully',
      url: 'You have successfully added a new blog!',
    })
    history.push('/blogs');
    yield put({
      type: 'blog/LOAD_BLOG',
      payload: {  page:1  },
    })
   
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
    
  }
}

export function* UPDATE( {payload} ) {
  const { title,  url,Files,  id } = payload
  yield put({
    type: 'blog/SET_STATE',
    payload: {
      loading: true,
    },
  })

  if (!(  id)) {
    notification.error({
      message: 'Error',
      url: 'Please fill the form properly!',
    })
    return
  }

  const success = yield call(update, id, title,  url,Files||{})
  if (success) {
    notification.success({
      message: 'Blog Updated Successfully',
      url: 'You have successfully updated your new blog!',
    })
    history.push('/blogs');
    yield put({
      type: 'blog/LOAD_BLOG',
      payload: {  limit: 10, skip: 0 },
    
    })
 
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_BLOG( p ) {
  const payload = p.payload ||{page: 1}
  const {  page } = payload

  const response = yield call(loadblog,  page)

  if (response) {
    // eslint-disable-next-line camelcase
    console.log(response)
    yield put({
      type: 'blog/SET_STATE',
      payload: {
        list: response.data,
        total: response.total,
        per_page: response.per_page,
        current: response.current_page
      },
    })
    
  }
}


export function* DELETE  ( payload ) {
  const { id} = payload
  if (!(id)) {
    notification.error({
      message: 'Error',
      url: 'Please fill the form properly!',
    })
    return
  }
  const success = yield call(deleteblog, id);
  console.log(success)
  if (success) {
    notification.success({
      message: 'Blog Deleted Successfully',
      url: 'You have successfully deleted a blog!',
    })

    yield put({
      type: 'blog/LOAD_BLOG',
      payload: {  limit: 10, skip: 0 },
    })
   
   
    history.push('/blog');
    
  }
}






export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.LOAD_BLOG, LOAD_BLOG),
    takeEvery(actions.DELETE_BLOG, DELETE),
    LOAD_BLOG( { limit: 10, skip: 0 }), // run once on app load to check blog auth
  ])
}
